.track-information {
	display: flex;
	justify-content: space-between;
	column-gap: 50px;
}
.information-item {
	font-size: 14px !important;
	display: 'flex';
	align-items: 'center';
	flex-wrap: 'wrap';
}

.chart-information-align {
	height: 80px;
	width: 80px;
}
