/* .main-content{
    background-color: rgb(223, 213, 213);
} */

.information-header {
	display: flex;
	justify-content: space-between;
}
.information-header-main {
	display: flex;
	justify-content: space-between;
}

.main-content-information {
	display: flex;
	justify-content: space-between;
}

.main-information {
	margin-top: 1rem;
	height: 112px;
	width: 271px;
}
.main-information-align {
	display: flex;
	flex-direction: row;
}
.information-row1 {
	/* background-color: #f1f1f1; */
	width: 70%;
	/* border: 1px solid red; */
	text-align: left;
	/* line-height: 35px; */
}
.information-row2 {
	/* background-color: #f1f1f1; */
	width: 100%;
	/* border: 1px solid red; */
	text-align: left;
	/* line-height: 35px; */
}
.information-item {
	font-size: 14px !important;
}

.main-content-information-body {
	display: flex;
	justify-content: space-between;
	width: 700px;
	height: 110px;
}
.information-button {
	display: flex;
	justify-content: space-between;
	margin-top: 2%;
	padding: 10px;
	width: 100%;
}

.adec-qr-button-layout {
	display: flex;
	justify-content: space-between;
	column-gap: 10px;
}
.header-status {
	color: #11887f;
}

.main-content-information-track {
	height: 104;
	width: 220;
}

.download-summary-button {
	border-radius: 10px;
	color: #415a77 !important;
}
.publish-button {
	background-color: #415a77 !important;
	color: white !important;
	border-radius: 10px;
}

.search-create-track-layout {
	padding: 20px;
	display: flex;
	justify-content: space-between;
}

.create-track-button {
	border-radius: 10px;
	color: #415a77 !important;
}
