.main-information {
	margin-top: 1rem;
	height: 112px;
	width: 271px;
}
.main-information-align {
	display: flex;
	flex-direction: row;
}
.information-row1 {
	/* background-color: #f1f1f1; */
	width: 70%;
	/* border: 1px solid red; */
	text-align: left;
	/* line-height: 35px; */
}
.information-row2 {
	/* background-color: #f1f1f1; */
	width: 100%;
	/* border: 1px solid red; */
	text-align: left;
	/* line-height: 35px; */
}
.information-item {
	font-size: 14px !important;
}
