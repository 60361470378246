.user-add-button {
	color: red;
}
.button-align-right {
	float: right;
}
.handover-button-control {
	margin-bottom: 10px;
	margin-right: 5px;
	color: red;
}
.organization-button-control {
	color: red;
}
