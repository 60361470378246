.closeButton {
	text-align: right;
}

.login-forget-password {
	opacity: 0.5;
	transition: all 0.3s linear;
}
.login-forget-password:hover {
	opacity: 1;
	text-decoration: underline;
	cursor: pointer;
	color: red;
}

.login-in-btn {
	color: red;
	width: 50%;
	margin-top: 20px;
	margin-bottom: 10px;
	margin-left: 50%;
}

.password-reset-email-success {
	padding: 4px;
	border-radius: 4px;
	background-color: green;
	color: white;
}
.password-reset-email-error {
	padding: 4px;
	border-radius: 4px;
	background-color: red;
	color: white;
}

.error {
	color: red;
}

.organization-button-control {
	margin-bottom: 10px;
}

.group-header-center {
	text-align: center;
}
.login-input-field {
	height: 270px;
	max-height: 300px;
	overflow-y: scroll;
	overflow-x: hidden;
}
