.trackBoard-wrapper {
	height: 82vh;
	overflow-y: none;
	overflow-x: none;
	padding-top: 1rem;
	/* width: calc(100% - 1rem); */
	width: calc(100vw - 20rem);
	/* max-width: 100vWw; */
	max-width: 100vw;
	background-color: #ffffff;
	margin: 1rem;
	/* overflow: hidden; */
	/* -webkit-box-shadow: -2px -2px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -2px -2px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: -2px -2px 5px 0px rgba(0, 0, 0, 0.15); */
	border-top-left-radius: 0.5rem;
	border-bottom-left-radius: 0.5rem;
}

.trackBoardTable-wraper {
	margin-top: 0px;
	margin-left: 2%;
	margin-right: 2%;
	margin-bottom: 1%;
	/* height: 530px; */
	/* width: 1180px; */
	width: calc(100% - 3rem);
	/* max-height: 600px; */
	max-width: 100%;
	overflow-y: none;
}

.adminDashboard-wrapper {
	height: 80vh;
	overflow-y: auto;
	/* padding-top: 1rem; */
	width: calc(100% - 1rem);
	background-color: #ffffff;
	margin: 1rem;
	/* overflow: hidden; */
	/* -webkit-box-shadow: -2px -2px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -2px -2px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: -2px -2px 5px 0px rgba(0, 0, 0, 0.15); */
	border-top-left-radius: 0.5rem;
	border-bottom-left-radius: 0.5rem;
}

.adminDashboardTable-wraper {
	margin-top: 0px;
	margin-left: 3%;
	margin-right: 3%;
}
